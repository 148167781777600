
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddAccountValidationModal from "@/views/task-management/account-validation/AddAccountValidationModal.vue";
import FilterDropdwon from "@/views/task-management/account-validation/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface AccountValidation {
  id: number;
  name: string;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "account-validation",
  components: {
    MBDatatable,
    FilterDropdwon,
    AddAccountValidationModal,
  },
  setup() {
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<AccountValidation>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const { t } = useI18n();
    const tableHeader = ref([
      {
        name: t("accountValidation.influencerAccount"),
        key: "account_id",
        sortable: true,
      },
      {
        name: t("accountValidation.validationDate"),
        key: "validation_date",
        sortable: false,
      },
      {
        name: t("accountValidation.validatedDy"),
        key: "validated_by",
        sortable: false,
      },
      {
        name: t("accountValidation.registrationMode"),
        key: "registration_mode",
        sortable: false,
      },
      {
        name: t("accountValidation.registrationChannel"),
        key: "registration_channel",
        sortable: false,
      },
      {
        name: t("accountValidation.membershipLevel"),
        key: "membership_level",
        sortable: false,
      },
      {
        name: t("accountValidation.status"),
        key: "status",
        sortable: false,
      },
      {
        name: t("accountValidation.createdAt"),
        key: "created_at",
        sortable: true,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getAccountValidationList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case 10:
          statusClass = "badge-light-warning";
          break;
        case 20:
          statusClass = "badge-light-info";
          break;
        case 30:
          statusClass = "badge-light-success";
          break;
        case 40:
          statusClass = "badge-light-danger";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const getAccountValidationList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiTaskManagement.getAccountValidationList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          filterArr.push({
            field: item !== "status" ? item : "account_validations.status",
            value: val[item],
            condition: "eq",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getAccountValidationList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getAccountValidationList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getAccountValidationList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getAccountValidationList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getAccountValidationList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getAccountValidationList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getAccountValidationList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getAccountValidationList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      statusClass,
      updateList,
    };
  },
});
