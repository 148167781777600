
import { ApiBase, ApiChanel } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  validated_by: string;
  registration_channel: string;
  invite_by: string;
  recommended_by: string;
}

export default defineComponent({
  name: "account-validation-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      validated_by: "",
      registration_channel: "",
      invite_by: "",
      recommended_by: "",
    });
    const { t } = useI18n();
    const validatedByOptions = ref([]);
    const statusOption = ref([
      { label: t("accountValidation.unverified"), value: 10 },
      { label: t("accountValidation.processing"), value: 20 },
      { label: t("accountValidation.verified"), value: 30 },
      { label: t("accountValidation.refused"), value: 40 },
    ]);
    const validatedByLoading = ref<boolean>(false);
    const registrationChannelOptions = ref([]);
    const registrationChannelLoading = ref<boolean>(false);
    const inviteByOptions = ref([]);
    const inviteByLoading = ref<boolean>(false);
    const recommendedByOptions = ref([]);
    const recommendedByLoading = ref<boolean>(false);

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (validatedByLoading.value = true)
            : (validatedByLoading.value = false);
          break;
        case 1:
          status
            ? (inviteByLoading.value = true)
            : (inviteByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          validatedByOptions.value = data;
          break;
        case 1:
          inviteByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchValidatedByItems = (query) => {
      debounceUserSearch(0, query);
    };
    const searchInviteByItems = (query) => {
      debounceUserSearch(1, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 1:
          status
            ? (recommendedByLoading.value = true)
            : (recommendedByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 1:
          recommendedByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchRecommendedByItems = (query) => {
      debounceAccountSearch(1, query);
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["account_registration_channel"],
      });
      if (data.code == 0) {
        registrationChannelOptions.value =
          data.data.account_registration_channel.items;
      }
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onBeforeMount(() => {
      getDropdownOptions();
    });

    return {
      data,
      formRef,
      validatedByOptions,
      validatedByLoading,
      statusOption,
      registrationChannelOptions,
      registrationChannelLoading,
      inviteByOptions,
      inviteByLoading,
      recommendedByOptions,
      recommendedByLoading,
      t,
      searchValidatedByItems,
      searchInviteByItems,
      searchRecommendedByItems,
      submit,
      handleReset,
    };
  },
});
