
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiTaskManagement, ApiBase, ApiCampaign } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-account-validation-modal",
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addAccountValidationModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();
    const formData = ref({
      account_id: "",
      validation_date: "",
      validated_by: "",
      registration_mode: "",
      registration_channel: "",
      invite_by: "",
      recommended_by: "",
      invite_campaign: "",
      membership_level: "",
      validation_notice: "",
    });

    const rules = ref({
      account_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const influencerOptions = ref([]);
    const influencerLoading = ref<boolean>(false);
    const validatedByOptions = ref([]);
    const validatedByLoading = ref<boolean>(false);
    const registrationModeOptions = ref([]);
    const registrationModeLoading = ref<boolean>(false);
    const registrationChannelOptions = ref([]);
    const registrationChannelLoading = ref<boolean>(false);
    const inviteByOptions = ref([]);
    const inviteByLoading = ref<boolean>(false);
    const recommendedByOptions = ref([]);
    const recommendedByLoading = ref<boolean>(false);
    const inviteCampaignOptions = ref([]);
    const inviteCampaignLoading = ref<boolean>(false);
    const membershipLevelOptions = ref([]);
    const membershipLevelLoading = ref<boolean>(false);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "account_registration_mode",
          "account_registration_channel",
        ],
      });
      if (data.code == 0) {
        registrationModeOptions.value =
          data.data.account_registration_mode.items;
        registrationChannelOptions.value =
          data.data.account_registration_channel.items;
      }
    };

    const getCampaignsSourceData = async () => {
      inviteCampaignLoading.value = true;
      const { data } = await ApiCampaign.getCampaignsSourceData({
        max_item: "all",
      });
      inviteCampaignLoading.value = false;
      if (data.code == 0) {
        inviteCampaignOptions.value = data.data;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (validatedByLoading.value = true)
            : (validatedByLoading.value = false);
          break;
        case 1:
          status
            ? (inviteByLoading.value = true)
            : (inviteByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          validatedByOptions.value = data;
          break;
        case 1:
          inviteByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchValidatedByItems = (query) => {
      debounceUserSearch(0, query);
    };
    const searchInviteByItems = (query) => {
      debounceUserSearch(1, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (influencerLoading.value = true)
            : (influencerLoading.value = false);
          break;
        case 1:
          status
            ? (recommendedByLoading.value = true)
            : (recommendedByLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          influencerOptions.value = data;
          break;
        case 1:
          recommendedByOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchAccountItems = (query) => {
      debounceAccountSearch(0, query);
    };
    const searchRecommendedByItems = (query) => {
      debounceAccountSearch(1, query);
    };

    const getInfluencerGroupSourceData = async () => {
      membershipLevelLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      membershipLevelLoading.value = false;
      if (data.code == 0) {
        membershipLevelOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiTaskManagement.addAccountValidation(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addAccountValidationModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addAccountValidationModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getDropdownOptions();
      getCampaignsSourceData();
      getInfluencerGroupSourceData();
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      addAccountValidationModalRef,
      submitButton,
      influencerOptions,
      influencerLoading,
      validatedByOptions,
      validatedByLoading,
      registrationModeOptions,
      registrationModeLoading,
      registrationChannelOptions,
      registrationChannelLoading,
      inviteByOptions,
      inviteByLoading,
      recommendedByOptions,
      recommendedByLoading,
      inviteCampaignOptions,
      inviteCampaignLoading,
      membershipLevelOptions,
      membershipLevelLoading,
      t,
      searchValidatedByItems,
      searchInviteByItems,
      searchAccountItems,
      searchRecommendedByItems,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
